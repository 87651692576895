<template>
  <div>
    <div class="d-flex justify-content-center" v-if="carregando">
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>

    <b-form ref="frmEmail" v-if="!carregando">
      <b-form-group>
        <label>Servidor <span class="text-danger">*</span>:</label>
        <b-form-input
          name="servidor"
          v-model="frmEmail.servidor"
        ></b-form-input>
      </b-form-group>

      <div class="row">
        <div class="col-6">
          <b-form-group>
            <label>Nome de usuário <span class="text-danger">*</span>:</label>
            <b-form-input
              name="usuario"
              v-model="frmEmail.usuario"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group>
            <label>Senha <span class="text-danger">*</span>:</label>
            <b-form-input
              type="password"
              name="senha"
              v-model="frmEmail.senha"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <b-form-group>
            <label>Porta <span class="text-danger">*</span>:</label>
            <b-form-input name="porta" v-model="frmEmail.porta"></b-form-input>
          </b-form-group>
        </div>

        <div class="col-4">
          <b-form-group>
            <label>Criptografia <span class="text-danger">*</span>:</label>
            <b-form-select
              name="criptografia"
              v-model="frmEmail.criptografia"
              :options="sltCriptografia"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="col-4">
          <b-form-group>
            <label>Driver: </label>
            <b-form-input
              name="driver"
              v-model="frmEmail.driver"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group>
            <label>Domínio:</label>
            <b-form-input
              name="dominio"
              v-model="frmEmail.dominio"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group>
            <label>Token:</label>
            <b-form-input name="token" v-model="frmEmail.token"></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group>
            <label>Remetente <span class="text-danger">*</span>:</label>
            <b-form-input
              name="remetente"
              v-model="frmEmail.remetente"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group>
            <label>Responder para <span class="text-danger">*</span>:</label>
            <b-form-input
              name="responderPara"
              v-model="frmEmail.responderPara"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import { mapGetters } from "vuex";

export default {
  props: {
    conta: Object
  },

  data() {
    return {
      frmEmail: {
        servidor: "",
        usuario: "",
        senha: "",
        porta: "",
        criptografia: "Nenhum",
        driver: "",
        dominio: "",
        token: "",
        remetente: "",
        responderPara: ""
      },
      sltCriptografia: ["Nenhuma", "SSL", "TLS"],
      carregando: true
    };
  },

  mounted() {
    this.abrir();
  },

  computed: {
    ...mapGetters(["validatorErrors"])
  },

  methods: {
    abrir() {
      const self = this;

      ApiService.post("conta/obter-configuracao-email-smtp", {
        idConta: this.conta.id
      })
        .then((response) => {
          const configuracao = response.data.data;

          this.frmEmail.idConta = this.conta.id;

          if (configuracao) {
            this.frmEmail.servidor = configuracao.servidor;
            this.frmEmail.usuario = configuracao.usuario;
            this.frmEmail.senha = configuracao.senha;
            this.frmEmail.porta = configuracao.porta;
            this.frmEmail.criptografia = configuracao.criptografia;
            this.frmEmail.driver = configuracao.driver;
            this.frmEmail.dominio = configuracao.dominio;
            this.frmEmail.token = configuracao.token;
            this.frmEmail.remetente = configuracao.remetente;
            this.frmEmail.responderPara = configuracao.responder_para;
          }

          this.carregando = false;
        })
        .then(function () {
          self.validacao();

          self.$watch(
            "frmEmail",
            function () {
              self.$emit("aba-alterada", true);
            },
            { deep: true }
          );
        });
    },

    validacao() {
      const frmEmail = this.$refs["frmEmail"];
      const self = this;

      this.fv = formValidation(frmEmail, {
        fields: {
          servidor: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          usuario: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          senha: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          porta: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          criptografia: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          remetente: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          responderPara: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar() {
      return this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post(
            "conta/atualizar-configuracao-email-smtp",
            this.frmEmail
          )
            .then(() => {
              this.$emit("aba-alterada", false);

              Swal.fire({
                title: "MENSAGEM",
                text: "Atualizado com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    }
  }
};
</script>
