<template>
  <div>
    <div class="d-flex justify-content-center" v-if="carregando">
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>

    <b-form ref="frmChat" v-if="!carregando">
      <b-form-group>
        <div class="checkbox-inline">
          <label class="checkbox font-weight-normal">
            <input
              type="checkbox"
              name="habilitarMensagensChat"
              v-model="frmChat.habilitarMensagensChat"
            />
            <span></span>
            Habilitar mensagens de chat
          </label>
        </div>
      </b-form-group>

      <div v-show="frmChat.habilitarMensagensChat">
        <b-form-group>
          <label>Serviço de chat <span class="text-danger">*</span>:</label>
          <b-form-select
            name="servicoChat"
            :options="sltServicoChat"
            v-model="frmChat.servicoChat"
          ></b-form-select>
        </b-form-group>

        <div class="row" v-if="isAdmin">
          <div class="col-md-6">
            <b-form-group>
              <div class="checkbox-inline">
                <label class="checkbox font-weight-normal">
                  <input
                    type="checkbox"
                    name="tarifarAnexo"
                    v-model="frmChat.tarifarAnexo"
                  />
                  <span></span>
                  Tarifar envio de anexo
                </label>
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group>
              <div class="checkbox-inline">
                <label class="checkbox font-weight-normal">
                  <input
                    type="checkbox"
                    name="tarifarWaOficial"
                    v-model="frmChat.tarifarWaOficial"
                  />
                  <span></span>
                  Tarifar Whatsapp oficial dobrado
                </label>
              </div>
            </b-form-group>
          </div>
        </div>

        <b-form-group>
          <div class="checkbox-inline">
            <label class="checkbox font-weight-normal">
              <input
                type="checkbox"
                name="habilitarRespostaChamada"
                v-model="frmChat.habilitarRespostaChamada"
              />
              <span></span>
              Habilitar resposta em caso de Chamadas
            </label>
          </div>
        </b-form-group>

        <b-form-group v-show="frmChat.habilitarRespostaChamada">
          <label
            >Texto da resposta da chamada
            <span class="text-danger">*</span>:</label
          >
          <b-form-textarea
            name="textoRespostaChamada"
            v-model="frmChat.textoRespostaChamada"
            rows="3"
          ></b-form-textarea>
        </b-form-group>

        <div class="row">
          <div class="col-md-6">
            <b-form-group>
              <label>Token<span class="text-danger">*</span>:</label>
              <b-form-input
                name="token"
                v-model="frmChat.token"
                :disabled="!isAdmin"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group>
              <label
                >Tratamento das respostas
                <span class="text-danger">*</span>:</label
              >
              <b-form-select
                name="tratamentoRespostas"
                :options="sltTratamentoResposta"
                v-model="frmChat.tratamentoRespostas"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>

        <b-form-group v-show="exibirTextoTratamentoResposta">
          <label>Texto da resposta <span class="text-danger">*</span>:</label>
          <b-form-textarea
            name="textoResposta"
            v-model="frmChat.textoResposta"
            rows="3"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

export default {
  props: {
    conta: Object
  },
  data() {
    return {
      sltServicoChat: ["senderzap"],
      sltTratamentoResposta: [
        {
          value: "SV",
          text: "Salvar respostas"
        },
        {
          value: "RS",
          text: "Enviar resposta padrão e salvar"
        },
        {
          value: "RI",
          text: "Enviar resposta padrão e ignorar"
        },
        {
          value: "IG",
          text: "Ignorar"
        }
      ],
      frmChat: {
        idConta: null,
        habilitarMensagensChat: false,
        servicoChat: "senderzap",
        tarifarAnexo: false,
        tarifarWaOficial: false,
        habilitarRespostaChamada: false,
        textoRespostaChamada: "",
        token: "",
        tratamentoRespostas: "SV",
        textoResposta: ""
      },
      exibirTextoTratamentoResposta: false,
      carregando: true
    };
  },

  mounted() {
    this.abrir();
  },

  computed: {
    ...mapGetters(["isAdmin", "validatorErrors"])
  },

  watch: {
    "frmChat.tratamentoRespostas": function (valor) {
      this.exibirTextoTratamentoResposta = ["RS", "RI"].includes(valor);
    }
  },

  methods: {
    abrir() {
      const self = this;

      ApiService.post("conta/obter-dados-chat", {
        idConta: this.conta.id
      })
        .then((response) => {
          const chat = response.data.data;

          this.frmChat.idConta = this.conta.id;
          this.frmChat.habilitarMensagensChat = chat.habilitarMensagensChat;
          this.frmChat.servicoChat = chat.servicoChat;
          this.frmChat.tarifarAnexo = chat.tarifarAnexo;
          this.frmChat.tarifarWaOficial = chat.tarifarWaOficial;
          this.frmChat.habilitarRespostaChamada = chat.habilitarRespostaChamada;
          this.frmChat.textoRespostaChamada = chat.textoRespostaChamada;
          this.frmChat.token = chat.token;
          this.frmChat.tratamentoRespostas = chat.tratamentoRespostas;
          this.frmChat.textoResposta = chat.textoResposta;

          this.carregando = false;
        })
        .then(function () {
          self.validacao();

          self.$watch(
            "frmChat",
            function () {
              self.$emit("aba-alterada", true);
            },
            { deep: true }
          );
        });
    },

    validacao() {
      const frmChat = this.$refs["frmChat"];
      const self = this;

      this.fv = formValidation(frmChat, {
        fields: {
          servicoChat: {
            validators: {
              callback: {
                message: "Campo obrigatório.",
                callback: function () {
                  if (!self.frmChat.habilitarMensagensChat) {
                    return true;
                  }

                  return !!self.frmChat.servicoChat;
                }
              },
              blank: {}
            }
          },
          textoRespostaChamada: {
            validators: {
              callback: {
                message: "Campo obrigatório.",
                callback: function () {
                  if (!self.frmChat.habilitarMensagensChat) {
                    return true;
                  }

                  if (!self.frmChat.habilitarRespostaChamada) {
                    return true;
                  }
                  return !!self.frmChat.textoRespostaChamada;
                }
              },
              blank: {}
            }
          },
          token: {
            validators: {
              callback: {
                message: "Campo obrigatório.",
                callback: function () {
                  if (!self.frmChat.habilitarMensagensChat) {
                    return true;
                  }

                  return !!self.frmChat.token;
                }
              },
              blank: {}
            }
          },
          textoResposta: {
            validators: {
              callback: {
                message: "Campo obrigatório.",
                callback: function () {
                  if (!self.frmChat.habilitarMensagensChat) {
                    return true;
                  }

                  if (
                    !["RS", "RI"].includes(self.frmChat.tratamentoRespostas)
                  ) {
                    return true;
                  }

                  return !!self.frmChat.textoResposta;
                }
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar() {
      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("conta/atualizar-chat", this.frmChat)
            .then(() => {
              this.$emit("aba-alterada", false);

              Swal.fire({
                title: "MENSAGEM",
                text: "Atualizado com sucesso!",
                icon: "success",
                heightAuto: false
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$emit("recursos-atualizados", false);
                }
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    }
  }
};
</script>
