<template>
  <div>
    <div class="d-flex justify-content-center" v-if="carregando">
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>

    <b-form ref="frmLiveChat" v-if="!carregando">
      <div class="row">
        <div class="col-9">
          <p>
            Atende Webhook URL:<br />
            <b-link @click.prevent="copiar" id="btnCopiar"
              ><i class="far fa-copy mr-2" style="color: #3699ff"></i
              >{{ `https://api.wellon.digital/wellonchat/eventos/${conta.id}` }}
              <b-tooltip ref="tooltipCopiar" target="btnCopiar" disabled>
                Copiado!
              </b-tooltip>
            </b-link>
          </p>
        </div>

        <div class="col-3">
          <template v-if="!existeCadastro">
            <modal-criar-conta
              :idConta="conta.id"
              @conta-criada="contaCriada"
            ></modal-criar-conta>
          </template>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <b-form-group>
            <label>Chat ID<span class="text-danger">*</span>:</label>
            <b-form-input
              name="chatwootId"
              v-model="frmLiveChat.chatwootId"
              data-inputmask="'alias': 'integer'"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-9">
          <b-form-group>
            <label>Opções:</label>
            <div class="checkbox-inline">
              <label class="checkbox font-weight-normal">
                <input
                  type="checkbox"
                  name="exibirPosicao"
                  v-model="frmLiveChat.exibirPosicao"
                />
                <span></span>
                Exibir posição na fila
              </label>
            </div>
          </b-form-group>
        </div>
      </div>
      <b-form-group>
        <label>Inbox token<span class="text-danger">*</span>:</label>
        <b-form-input
          name="inboxToken"
          v-model="frmLiveChat.inboxToken"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <label>Admin token<span class="text-danger">*</span>:</label>
        <b-form-input
          name="adminToken"
          v-model="frmLiveChat.adminToken"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <label>Bot token:</label>
        <b-form-input
          name="botToken"
          v-model="frmLiveChat.botToken"
        ></b-form-input>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";
import ModalCriarConta from "./livechat/ModalCriarConta";

export default {
  props: {
    conta: Object
  },

  data() {
    return {
      frmLiveChat: {
        chatwootId: "",
        inboxToken: "",
        adminToken: "",
        botToken: "",
        exibirPosicao: false
      },
      carregando: true,
      existeCadastro: false
    };
  },

  components: { ModalCriarConta },

  mounted() {
    this.abrir();
  },

  methods: {
    abrir() {
      const self = this;

      ApiService.post("conta/obter-configuracao-live-chat", {
        idConta: this.conta.id
      })
        .then((response) => {
          const configuracao = response.data.data;

          this.frmLiveChat.idConta = this.conta.id;
          if (configuracao) {
            this.frmLiveChat.chatwootId = configuracao.chatwoot_id;
            this.frmLiveChat.inboxToken = configuracao.inbox_token;
            this.frmLiveChat.adminToken = configuracao.admin_token;
            this.frmLiveChat.botToken = configuracao.bot_token;
            this.frmLiveChat.exibirPosicao =
              configuracao.posicao == "S" ? true : false;

            this.existeCadastro = true;
          }

          this.carregando = false;
        })
        .then(function () {
          self.validacao();

          self.$watch(
            "frmLiveChat",
            function () {
              self.$emit("aba-alterada", true);
            },
            { deep: true }
          );
        });
    },

    validacao() {
      const frmLiveChat = this.$refs["frmLiveChat"];
      const self = this;

      Inputmask().mask(frmLiveChat.querySelectorAll("input"));

      this.fv = formValidation(frmLiveChat, {
        fields: {
          chatwootId: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          inboxToken: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          adminToken: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar() {
      return this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post(
            "conta/atualizar-configuracao-live-chat",
            this.frmLiveChat
          )
            .then(() => {
              this.$emit("aba-alterada", false);

              Swal.fire({
                title: "MENSAGEM",
                text: "Atualizado com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },

    copiar() {
      navigator.clipboard
        .writeText(
          `https://api.wellon.digital/wellonchat/eventos/${this.conta.id}`
        )
        .then(() => {
          this.$refs.tooltipCopiar.$emit("open");
          setTimeout(() => {
            this.$refs.tooltipCopiar.$emit("close");
          }, 1000);
        });
    },

    contaCriada(conta) {
      this.frmLiveChat.chatwootId = conta.chatwoot_id;
      this.frmLiveChat.inboxToken = conta.inbox_token;
      this.frmLiveChat.adminToken = conta.admin_token;
      this.frmLiveChat.exibirPosicao = "S";

      this.$emit("aba-alterada", false);
    }
  }
};
</script>
