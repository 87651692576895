<template>
  <div>
    <div class="d-flex justify-content-center" v-if="carregando">
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>

    <div v-else>
      <modal-formulario-unidade
        :idConta="conta.id"
        :tiposUnidades="tiposUnidades"
        @unidade-salva="unidadeSalva"
      ></modal-formulario-unidade>

      <b-table
        hover
        small
        show-empty
        :fields="cabecalho"
        :items="unidades"
        sort-by="und_nome"
      >
        <template #empty>
          <p class="text-center mb-0">Nenhum registro encontrado.</p>
        </template>

        <template #cell(actions)="row">
          <modal-formulario-unidade
            :idConta="conta.id"
            acao="editar"
            :unidade="row.item"
            :tiposUnidades="tiposUnidades"
            @unidade-salva="unidadeSalva"
          ></modal-formulario-unidade>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import ModalFormularioUnidade from "./unidades/ModalFormularioUnidade";

export default {
  props: {
    conta: Object
  },

  data() {
    return {
      unidades: [],
      tiposUnidades: [],
      carregando: true,
      unidadeSelecionada: null
    };
  },

  components: {
    "modal-formulario-unidade": ModalFormularioUnidade
  },

  computed: {
    ...mapGetters(["isAdmin", "hasPermission"]),

    cabecalho() {
      const cabecalho = [
        {
          key: "und_codigo",
          label: "Código",
          sortable: true,
          class: "align-middle text-center"
        },
        {
          key: "und_nome",
          label: "Nome",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "und_cnpj",
          label: "CNPJ",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "actions",
          label: "Ações",
          sortable: false,
          class: "align-middle text-center"
        }
      ];

      return cabecalho;
    }
  },

  mounted() {
    this.obterUnidades(true);
  },

  methods: {
    obterUnidades(inicializar = false) {
      ApiService.post("conta/obter-unidades-nps", {
        idConta: this.conta.id
      }).then((response) => {
        this.unidades = response.data.data.unidades;
        this.tiposUnidades = response.data.data.tiposUnidades;

        if (inicializar) {
          this.carregando = false;
        }
      });
    },

    unidadeSalva(unidades) {
      this.unidades = [...unidades];
    },

    excluir(id, index) {
      const self = this;

      Swal.fire({
        title: "Deseja realmente excluir esta unidade?",
        showCancelButton: true,
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.post("unidade/excluir", {
            idUnidade: id,
            idConta: this.conta.id
          }).then(() => {
            Swal.fire({
              title: "MENSAGEM",
              text: "Unidade excluída com sucesso!",
              icon: "success",
              heightAuto: false
            });

            self.unidades.splice(index, 1);
          });
        }
      });
    },

    cadastrarUnidade() {
      this.unidadeSelecionada = null;

      this.$refs.mdlFormularioUnidade.abrir();
    },

    editarUnidade(unidade) {
      this.unidadeSelecionada = { ...unidade };

      this.$refs.mdlFormularioUnidade.abrir();
    }
  }
};
</script>
